.Section {
    height: fit-content;
    min-height: calc(var(--fh) - 8rem);
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 3rem;
    display: flex;
    justify-content: center;
}

