.About {
    align-items: left;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.wrappedImage {
    float: left;
    image-rendering: pixelated;
    height: 8rem;
    position: relative;
    top: -10px;
    left: -20px;
    margin-right: -30px;
    margin-bottom: -10px;
}

.listIcon {
    margin-right: -1rem;
}

.padBot {
    margin-bottom: 0.4rem;
}

.listContiner {
    position: relative;
    left: -1rem;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
}
