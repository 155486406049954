.Project {
    width: 100%;
}

.Image {
    width: 100%;
    height: 100%
}

.projectName {
    padding-right: 2rem;
}

.Carousel {
    margin-bottom: 1rem;
}

.credit {
    padding: 1rem;
}

.img {
    object-fit: scale-down;
    width: 100%;
    height: 100%
}

.techContainer {
    flex-wrap: wrap;
    margin-right: -2rem;
    margin-left: -2rem;
    padding: 0.2rem 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.tech {
    padding-right: 1rem;
}

.title {
    margin-bottom: 1rem;
    margin-top: 1rem;
    flex-wrap: wrap;
}
