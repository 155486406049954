
.button {
    height: fit-content;
}

.header {
    box-shadow: var(--shadow-color);
    height: 3rem;
    padding: 0 1rem 0 2rem
}

