.Content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: calc(var(--fh) - 3rem);
    overflow-y: auto;
    overflow-x: hidden;
}

.viewPort {
    flex-direction: column;
    padding-right: 2rem;
    padding-left: 2rem;
    width: 40rem;
}
