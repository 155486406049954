.button {
    margin-right: 2rem;
}

.hidden {
    visibility: hidden;
}

.cursor {
    animation: blinker 1s steps(1) infinite
}

@keyframes blinker {
    50% {
        border-right: 0.2rem solid greenyellow
    }
}

.Contact {
    align-items: left;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
}