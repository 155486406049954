.NavBar {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 3rem;
}



.bar {
    position: relative;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-direction: row;
    width: 44rem;
    scrollbar-width: none;  /* Firefox */
}

.bar::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
    -webkit-appearance: none;
    width: 0;
    height: 0;
}
.bar {
    scrollbar-width: none;  /* Firefox */
}

.textContainer {
    flex-grow: 1;
    display: flex;
    align-items: baseline;
}
