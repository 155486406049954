.glass {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.viewPort {
    flex-flow: row nowrap;
    display: flex;
    overflow-x: scroll;
    width: 100%;
    scroll-snap-type: x mandatory;
    border-radius: 0rem 0rem 1rem 1rem;
}

.panel:nth-child(even) {
    min-width: calc(100% - 2rem);
    scroll-snap-align: center;
    padding: 1rem 1rem;
    min-height: 5rem;
}

.panel:nth-child(odd) {
    min-width: calc(100% - 2rem);
    padding: 1rem 1rem;
    scroll-snap-align: center;
    min-height: 5rem;
}

.tab {
    padding: 0.5rem 1rem;
    border-radius: 1rem 1rem 0rem 0rem;
    width: fit-content;
}

.tab:hover {
    cursor: pointer;
}

.viewPort::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
    -webkit-appearance: none;
    width: 0;
    height: 0;
}

.viewPort {
    scrollbar-width: none;
    /* Firefox */
}
